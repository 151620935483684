































































































































































import { FormFornecedor, Fornecedor } from '@/models/Fornecedor'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { FindFornecedorUseCase, SaveFornecedorUseCase } from '@/usecases'
import { cnpjCpfValido, obrigatorio } from '@/shareds/regras-de-form'
import CampoDeTelefone from '@/components/ui/CampoDeTelefone.vue'
import { nextTick } from '@/shareds/utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import { TipoDeOperacao } from '@/models/Recurso'
import CamposDeEndereco from '@/components/ui/CamposDeEndereco.vue'
import CampoDeCnpjOuCpf from '@/components/ui/CampoDeCnpjOuCpf.vue'
import SeletorDeMarca from '../produto/SeletorDeMarca.vue'

type AbaDoFornecedor = 'Principal' | 'Contatos' 

@Component({
	components: {
		CampoDeTelefone,
		CamposDeEndereco,
		CampoDeCnpjOuCpf,
		SeletorDeMarca,
	},
})
export default class DialogoDeEdicaoDeFornecedor extends Vue {
	@Ref() campoDeNome!: HTMLInputElement
	@Ref() form!: HTMLFormElement
	salvando = false
	fornecedor: FormFornecedor | null = null
	mostra = false
	valid = false
	saveFornecedorUseCase = new SaveFornecedorUseCase()
	findFornecedorUseCase = new FindFornecedorUseCase()
	obrigatorio = obrigatorio
	buscandoCnpjOuCpf = false
	autoCompletaEndereco = true
	errors: string[] = []
	tabs: AbaDoFornecedor[] = ['Principal', 'Contatos']
	tab: null | number = null
	criandoFornecedor = false

	get abasPermitidas() {
		return this.tabs
	}

	get podeAlterarFornecedor() {
		return this.podeRealizarOperacao('alterar')
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !'fornecedores' || UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'fornecedores'))
	}

	$refs!: {
		[key: string]: HTMLFormElement | CampoDeTelefone
	}
	resolveDialogo: ((fornecedor: Fornecedor | null) => void) | null = null

	async mostrar(fornecedor: FormFornecedor, criandoNovoFornecedor: boolean): Promise<Fornecedor | null> {
		this.fornecedor = JSON.parse(JSON.stringify(fornecedor))
		this.mostra = true
		this.autoCompletaEndereco = true
		this.criandoFornecedor = criandoNovoFornecedor
		setTimeout(() => {
			this.form.resetValidation()
			this.campoDeNome.focus()
		})
		return new Promise(resolve => this.resolveDialogo = resolve)
	}

	esconder() {
		this.mostra = false
		this.resolveDialogo && this.resolveDialogo(null)
	}

	adicionarTelefone() {
		if (!this.fornecedor) return

		this.fornecedor.telefones.push('')
		this.$nextTick(() => {
			if (!this.fornecedor) return
			const campo = this.$refs[`telefone-${this.fornecedor.telefones.length - 1}`]
			campo[0].$children[0].focus()
		})
	}

	validarFormularios() {
		for (let i = 0; i < this.abasPermitidas.length; ++i) {
			const campo = this.$refs[`form${i}`]
			if ('validate' in campo && campo.validate()) continue
			this.tab = i
			return false
		}
		return true
	}

	async salvar() {
		await nextTick()
		if (!this.fornecedor) return
		if (!this.validarFormularios()) return
		try {
			this.salvando = true

			const fornecedor = {
				...this.fornecedor,
				cnpjOuCpf: this.fornecedor.cnpjOuCpf.replace(/\D/g, ''),
			}

			const fornecedorSalvo = this.fornecedor.id
				? await this.saveFornecedorUseCase.update(fornecedor)
				: await this.saveFornecedorUseCase.create(fornecedor)

			this.$emit('confirmado', fornecedorSalvo)
			this.resolveDialogo && this.resolveDialogo(fornecedorSalvo)
			this.mostra = false
			AlertModule.setSuccess('Fornecedor salvo com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	async onCnpjOuCpf() {
		if (!this.fornecedor) return
		const cnpjOuCpf = this.fornecedor.cnpjOuCpf
		if (!cnpjOuCpf || !cnpjCpfValido(cnpjOuCpf)) return
		try {
			this.buscandoCnpjOuCpf = true
			const params = { busca: cnpjOuCpf.replace(/\D/g, '') }
			const data = await this.findFornecedorUseCase.find(params)
			if (!data.content.length) return
			const fornecedor = data.content[0]
			this.autoCompletaEndereco = false
			this.fornecedor.id = fornecedor.id
			this.fornecedor.razaoSocialOuNome = fornecedor.razaoSocialOuNome
			this.fornecedor.email = fornecedor.email
			this.fornecedor.inscricaoSuframa = fornecedor.inscricaoSuframa
			this.fornecedor.inscricaoEstadual = fornecedor.inscricaoEstadual
			this.fornecedor.telefones = fornecedor.telefones
			
			this.fornecedor.endereco = JSON.parse(JSON.stringify(fornecedor.endereco))
		} catch (e) {
			this.errors.push(e.message)
		} finally {
			this.buscandoCnpjOuCpf = false
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if(mostra) return
		this.fornecedor = null
	}

}

